.top-container {
  display: flex;
  align-items: center; /* Add this to vertically center the elements */
  font-size: 22px;
}

.top-container__close-button {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: auto; /* Add this property to align the button to the right */
  border: 0;
}

.top-container__close-button:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}

.top-container__close-button img {
  /* The width: 100% and height: 100% properties make the image element expand to fill the width and height of the parent. */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkbox-container {
  display: flex;
  margin: 10px;
}

.checkbox-container__column {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.checkbox {
  align-items: center;
}

.checkbox-label {
  margin-left: 5px;
}

.filter-selection-button {
  padding: 4px 10px;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.filter-selection-button:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}

.filter-selection-button:active {
  background-color: lightgray;
}
