.intervals-list {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex: 1; /* take up the remaining space */
  /* border: 1px solid gray;
  background-color: #6e1adbaa; */
}

.intervals-list li {
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.intervals-list li:hover {
  background-color: #dbdbdb;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}

.intervals-list li.active {
  color: blue;
}
