.top-container {
  display: flex;
  align-items: center; /* Add this to vertically center the elements */
  font-size: 22px;
}

.radio-container {
  display: flex;
  margin: 10px;
}

.radio-container__column {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.radio {
  align-items: center;
}

.radio-label {
  margin-left: 5px;
}
