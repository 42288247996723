.results-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid gray;
  list-style: none;
  padding: 0;
  width: 500px;
  max-height: 600px;
  overflow-y: scroll;
  z-index: 2;
}

.results-list:empty {
  border: none;
}
