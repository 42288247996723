.stock-ohlc {
  font-size: 14px;
  margin-left: 20px;
}

.green {
  color: limegreen;
}

.red {
  color: red;
}
