.button {
  font: inherit;
  background: #7c0db0;
  border: 1px solid #7c0db0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border-radius: 20px;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  color: #e5cafb;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}
