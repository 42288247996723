.result-item {
  display: flex;
  justify-content: flex-start;
  /* When you set flex: 1; on a flex item, it is equivalent to the following: 
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      Here's what each of these properties means:
      1. flex-grow: This property determines how much the flex item can grow relative to the other flex items inside the same flex container. 
      When you set flex-grow: 1;, it means that the flex item can grow and take up any remaining space in the flex container after accommodating the sizes of other flex items.
      2. flex-shrink: This property controls how much the flex item can shrink relative to the other flex items. 
      By setting flex-shrink: 1;, it means the flex item can shrink if necessary to prevent overflow or maintain the overall proportion of the flex container.
      3. flex-basis: This property defines the initial size of the flex item before any remaining space is distributed. 
      When you set flex-basis: 0%;, it means that the flex item will have an initial size of 0% of the available space in the flex container.
      Using flex: 1; is a convenient way to make a flex item expand and fill the available space within its flex container. 
      It's commonly used in flexible layouts where you want the flex item to take up the remaining space evenly with other flex items.
  */
  flex: 1;
  align-items: center; /* vertically center the content */
  border: 0.5px solid #d3d3d3;
  cursor: pointer; /* It sets the cursor to a pointer shape, indicating that the element is interactive and can be clicked or activated. */
}

.result-item:hover,
.result-item:active {
  background-color: #d3d3d3aa;
}

.result-item__code {
  width: 90px;
  margin-right: 10px;
  overflow: hidden; /* any content that extends beyond the element's boundaries will be clipped and hidden. */
}

.result-item__name {
  flex: 1; /* take up the remaining space */
}

.result-item__image {
  width: 1.3rem;
  height: 1.3rem;
}

.result-item__image img {
  width: 1.3rem;
  height: 1.3rem;
  object-fit: cover;
}
