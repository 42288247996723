.top-panel-container {
  display: flex;
  font-size: 22px;
  /* background-color: #1adb27aa;
  border: 1px solid gray; */
}

.top-panel-container__separator {
  border-left: 1px solid #d3d3d3;
  margin: 0 10px;
}

.top-panel-container__button {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  cursor: pointer;
}

.top-panel-container__button:focus {
  outline: none;
}

.top-panel-container__button:hover,
.top-panel-container__button:active {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}

.top-panel-container__button img {
  /* The width: 100% and height: 100% properties make the image element expand to fill the width and height of the parent. */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
