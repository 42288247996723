.search-bar {
  /*
    Setting position: relative; on the search box container is necessary to establish a positioning context for the absolutely positioned search icon.
    
    When an element is positioned absolutely, it is positioned relative to its nearest positioned ancestor.
    If there is no positioned ancestor, it is positioned relative to the initial containing block, which is typically the viewport.

    In this case, by setting position: relative; on the search box container, you are making it the positioned ancestor for the search icon.
    This means that the icon will be positioned relative to the search box container and not relative to the viewport or any other element.

    By using position: relative;, you ensure that the search icon is positioned correctly inside the search box and that any positioning properties (like top, right, bottom, left) applied to the icon are relative to the search box container.

    In summary, position: relative; is required to establish the correct positioning context for the absolutely positioned search icon.
*/
  position: relative;
  /* Set the div to be a flex container */
  display: flex;
  align-items: center;
  /* border: 1px solid gray;
  background-color: #ed7c0baa; */
}

.search-bar__icon {
  position: absolute;
  /* Set the div to be a flex container */
  display: flex;
  align-items: center;
  justify-content: center;
  left: 5px;
  height: 1rem;
  width: 1rem;
}

.search-bar__icon img {
  /* The width: 100% and height: 100% properties make the image element expand to fill the width and height of the parent div. */
  width: 100%;
  height: 100%;
}

.search-bar input {
  border-radius: 25px;
  padding-left: 25px;
  /* Make the input fit the height and width of the parent div */
  flex: 1; /* The flex: 1 property makes the input element take up the remaining horizontal space within the flex container. */
  /* The width: 100% and height: 100% properties make the element expand to fill the width and height of the parent div. */
  width: 100%;
  height: 100%;
  /* The box-sizing: border-box property ensures that the input element's width and height include any borders or padding applied to it. */
  box-sizing: border-box;
}
