.result-label {
  font-size: 18px;
}

.result-list-container {
  max-height: 610px;
  overflow: auto;
}

.result-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.result-list-item:hover,
.result-list-item:active {
  background-color: #d3d3d3aa;
}
